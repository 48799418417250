import { PATHS_WITHOUT_TAB_BAR, DETAIL_PAGE_PATHS } from './consts'

// 숫자 또는 한글만 허용하는 정규식 (한글형은 calculator 페이지 때문)
const ID_PATTERN = /^[\d가-힣]+$/

export const showTabBar = (pathname: string): boolean => {
  // 1. 명시적으로 제외된 페이지인 경우
  if (PATHS_WITHOUT_TAB_BAR.includes(pathname)) return false

  // 2. 상세 페이지 체크
  const isDetailPage = DETAIL_PAGE_PATHS.some((path) => {
    const param = pathname.replace(path, '').split('/')[0]
    const decodedParam = decodeURIComponent(param)

    return decodedParam && ID_PATTERN.test(decodedParam)
  })

  return !isDetailPage
}
