'use client'

import { PropsWithChildren, Suspense } from 'react'
import { usePathname } from 'next/navigation'
import NiceModal from '@ebay/nice-modal-react'
import clsx from 'clsx'
import { SWRConfig } from 'swr'
import JotaiDevtools from '@/components/JotaiDevtools'
import Loading from '@/components/LoadingBar'
import TabBar from '@/components/TabBar'
import { MaxWidthSize, MarginX, URL_PATH } from '@/consts'
import { handleError } from '@/utils/httpClient/handleError'
import { handleSWR } from '@/utils/httpClient/handleSWR'
import { showTabBar } from '../TabBar/utils'
import Toaster from '../Toaster'
import ClientInit from './ClientInit'
import Gnb from './Gnb'

/**
 * _app.tsx에서 모든 페이지를 감싸는 컴포넌트이다.
 */
const AppWrapper = ({ children }: PropsWithChildren) => {
  const pathname = usePathname()
  // GNB 페이지라면 wrapper의 z-index를 GNB 컴퍼넌트의 z-index(20임) 보다 높은 30으로 설정한다.
  const isGnb = pathname === URL_PATH.AppGnb
  // 어드민 상담 페이지는 너비를 더 넓게 쓴다.
  const isAdminChat = pathname === URL_PATH.PlusAdminChat
  const hasTabBar = showTabBar(pathname)

  // 플러스 페이지는 100vw이므로 MaxWidthSize를 적용하지 않는다.
  return (
    <SWRConfig
      value={{
        fetcher: handleSWR,
        onError: (error) => handleError(error),
      }}
    >
      <JotaiDevtools>
        <NiceModal.Provider>
          <div
            className={clsx('isolate', MarginX.center, {
              'z-30': isGnb,
              relative: isGnb,
              [MaxWidthSize.md]:
                !isAdminChat && pathname !== URL_PATH.PlusAdminChat,
              'max-w-[1040px]': pathname === URL_PATH.PlusAdminChat,
              'min-w-[1040px]': pathname === URL_PATH.PlusAdminChat,
            })}
          >
            <div className={clsx({ 'pb-[52px]': hasTabBar })}>{children}</div>
            {hasTabBar && <TabBar />}
          </div>
          <Suspense fallback={<Loading />}>
            <ClientInit />
          </Suspense>
          <Suspense fallback={<Loading />}>
            <Gnb />
          </Suspense>
          <Toaster />
        </NiceModal.Provider>
      </JotaiDevtools>
    </SWRConfig>
  )
}

export default AppWrapper
