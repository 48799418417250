import { useRouter, usePathname } from 'next/navigation'
import clsx from 'clsx'
import { useAtom } from 'jotai'
import { URL_PATH, MaxWidthSize } from '@/consts'
import useAuth from '@/hooks/useAuth'
import { gnbOpenAtom } from '@/stores'
import { isWebview } from '@/utils'
import { MENUS } from './consts'
import { showTabBar } from './utils'

/**
 * 하단 네비게이션 탭 바
 */
const TabBar = () => {
  const [isGnbOpen, setIsGnbOpen] = useAtom(gnbOpenAtom)
  const isApp = isWebview()

  const { push } = useRouter()
  const pathname = usePathname() ?? ''
  const { isLoggedIn } = useAuth()

  const handleMenuClick = (path: string) => {
    if (!isApp && path === URL_PATH.AppGnb) {
      setIsGnbOpen(true)
      return
    }
    // 마이케어 페이지에 접근할 때 로그인이 되어있지 않다면, 시작하기 페이지로 이동
    if (path === URL_PATH.MyCare && !isLoggedIn) push(URL_PATH.GetStarted)
    else {
      push(path)
    }
    setIsGnbOpen(false)
  }

  const isMenuActive = (path: string) => {
    // GNB 메뉴인 경우
    if (!isApp && path === URL_PATH.AppGnb) return isGnbOpen

    // GNB가 열려있으면 다른 메뉴는 모두 비활성화
    if (!isApp && isGnbOpen) return false

    // 메인 페이지는 정확히 일치할 때만 활성화
    if (path === URL_PATH.Main) return pathname === URL_PATH.Main

    // 마이케어(/users/me) 메뉴 처리
    if (path === URL_PATH.MyCare) {
      // /users/me/plus로 시작하는 경우는 제외
      if (pathname.startsWith(URL_PATH.PlusMain)) return false

      return (
        pathname.startsWith(URL_PATH.MyCare) ||
        pathname.startsWith(URL_PATH.GetStarted)
      )
    }

    // 플러스(/users/me/plus) 메뉴 처리
    if (path === URL_PATH.PlusMain)
      return pathname.startsWith(URL_PATH.PlusMain)

    return pathname.startsWith(path)
  }

  if (!showTabBar(pathname)) return null

  return (
    <div
      className={clsx(
        'bg-white',
        'fixed',
        'bottom-0',
        'z-30',
        'w-full',
        'h-[52px]',
        'flex',
        'border-t',
        'border-grey-100',
        MaxWidthSize.md,
      )}
    >
      {MENUS.map(({ text, icon, activeIcon, path, gtm }, index) => (
        <button
          onClick={() => handleMenuClick(path)}
          key={index}
          className={clsx(
            'flex',
            'flex-col',
            'items-center',
            'justify-center',
            'w-1/3',
            'h-full',
            'bg-white',
            'group',
          )}
          data-ga={gtm}
        >
          {/* 버튼이 active 상태가 아닌 기본 상태일 때 */}
          <span className={clsx('group-active:hidden')}>
            {isMenuActive(path) ? activeIcon : icon}
          </span>
          {/* 버튼이 active 상태일 때 */}
          <span className={clsx('hidden', 'group-active:inline-block')}>
            {activeIcon}
          </span>
          <p
            className={clsx(
              'prose-p4',
              { 'text-aqua-500': isMenuActive(path) },
              'group-active:text-aqua-500',
            )}
          >
            {text}
          </p>
        </button>
      ))}
    </div>
  )
}

export default TabBar
