import { useEffect } from 'react'
import useSWR from 'swr'
import { z } from 'zod'
import { API_PATH, AUTH_PROVIDER_KEY, STATUSES } from '@/consts'
import useAuth from '@/hooks/useAuth'
import { cancerObjectSchema } from '@/types/cancer'
import { getLocalStorage, setLocalStorage } from '@/utils'
import { logout } from '@/utils/auth'
import { handleCustomError } from '@/utils/httpClient/handleCustomError'
import { handleError } from '@/utils/httpClient/handleError'
import { handleSWR } from '@/utils/httpClient/handleSWR'

const questionSchema = z.object({
  count: z.number(),
  results: z.array(
    z.object({
      id: z.number(),
      question: z.string(),
      created_at: z.string().datetime({ offset: true }),
    }),
  ),
})
export type QuestionType = z.infer<typeof questionSchema>

const schema = z.object({
  name: z.nullable(z.string()),
  nickname: z.string(),
  email: z.string(),
  phone_number: z.nullable(z.string()),
  oauth_provider: z.enum(['KAKAO', 'NAVER', 'APPLE']),
  user_id: z.string(),
  cancer: z.nullable(cancerObjectSchema),
  status: z.nullable(z.enum(STATUSES)),
  answered_questions: questionSchema,
  waiting_questions: questionSchema,
  is_plus: z.boolean(),
  is_counsel_available: z.boolean().nullish(),
  bookmark_count: z.number(),
  question_count: z.number(),
  clinical_note_count: z.number(),
  counseling_schedule_label: z.string().nullish(),
  report_updated_label: z.string().nullish(),
  counsel_id: z.nullable(z.number()),
  id_for_external: z.string(),
})
export type MyInfo = z.infer<typeof schema>

const getMyData = (data: any) => {
  try {
    return schema.parse(data)
  } catch (error) {
    return undefined
  }
}

/**
 * 내 계정 정보('/api/me') 요청 후 response 반환
 * @param disableLoginBottomSheet 로그인 하지 않은 경우 로그인 바텀시트를 띄우지 않음. 기본값은 false.
 */
const useMyInfo = ({ disableLoginBottomSheet = false } = {}) => {
  const { isLoggedIn } = useAuth()
  const authProvider = getLocalStorage<string>(AUTH_PROVIDER_KEY, '')
  // 로그인 상태가 아니면 요청을 보내지 않음
  const { data, isLoading, error } = useSWR<MyInfo>(
    isLoggedIn ? API_PATH.MyInfo : null,
    handleSWR,
    {
      onError: (error: unknown) => {
        logout()
        if (!disableLoginBottomSheet) {
          handleError(error)
          return
        }
        handleCustomError({ error, ignoreCodes: [400, 401] })
      },
      revalidateOnFocus: false,
    },
  )

  useEffect(() => {
    if (!data?.oauth_provider) return
    // 로컬 스트리지에 저장된 authProvider와 서버에서 받아온 authProvider가 다르면 로컬 스트리지에 저장
    if (authProvider !== data.oauth_provider) {
      setLocalStorage(AUTH_PROVIDER_KEY, data?.oauth_provider)
    }
  }, [authProvider, data?.oauth_provider])

  return { myInfo: getMyData(data), isLoading, error }
}

export default useMyInfo
