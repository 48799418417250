import { useEffect } from 'react'
import { useParams, usePathname } from 'next/navigation'
import * as ChannelService from '@channel.io/channel-web-sdk-loader'
import * as Sentry from '@sentry/nextjs'
import { useAtomValue } from 'jotai'
import { CHANNEL_TALK_PAGES, URL_PATH } from '@/consts'
import useAuth from '@/hooks/useAuth'
import { gnbOpenAtom } from '@/stores'
import { isWebview } from '@/utils'

const useChannelService = () => {
  const pathname = usePathname()
  const idParam = useParams()?.id
  const { isLoggedIn } = useAuth()
  // 질문과 답변 상세페이지
  const isBoardView = pathname.startsWith(URL_PATH.Boards) && !!idParam
  const isChannelTalkOpened =
    CHANNEL_TALK_PAGES[pathname ?? ''] !== undefined || isBoardView
  const pluginKey = `${process.env.NEXT_PUBLIC_CHANNEL_TALK_PLUGIN_KEY}`
  const isApp = isWebview()
  const isGnbOpen = useAtomValue(gnbOpenAtom)

  useEffect(() => {
    ChannelService.loadScript()
  }, [])

  useEffect(() => {
    ChannelService.boot(
      {
        pluginKey: pluginKey,
        profile: {
          name: '고객',
        },
        hidePopup: !isChannelTalkOpened,
        hideChannelButtonOnBoot: true,
      },
      () => {
        // 채널톡 버튼을 미노출 조건:
        // 1. 게시판 상세 페이지가 아니면서 로그인하지 않은 경우
        // 2. GNB 메뉴가 열려있는 경우
        if ((!isBoardView && !isLoggedIn) || isGnbOpen) return
        // 마이페이지에서만 채널톡 버튼을 보여준다.
        if (isChannelTalkOpened) {
          ChannelService.showChannelButton()

          setTimeout(() => {
            const shadowHost = document.querySelector('#ch-plugin-entry > div')
            const shadowRoot = shadowHost?.shadowRoot
            const targetElement = shadowRoot?.querySelector(
              '[data-ch-testid="launcher"]',
            )

            // 채널톡 버튼을 못찾으면 Sentry에 에러를 기록한다.
            if (!targetElement) {
              Sentry.captureException(
                new Error('채널톡 버튼을 찾을 수 없습니다.'),
              )
              return
            }

            // 질문 답변 상세에서는 채널톡 버튼을 숨긴다. (마케팅 메시지만 노출될 수 있도록)
            if (isBoardView)
              (targetElement as HTMLElement).style.display = 'none'
            else (targetElement as HTMLElement).style.bottom = '66px' // 탭 높이 (52) + 버튼 bottom 위치 (14)
          }, 0)
          return
        }
        // 위 경우 외에는 채널톡을 노출하지 않는다.
        ChannelService.hideChannelButton()
      },
    )
  }, [
    isLoggedIn,
    pluginKey,
    isChannelTalkOpened,
    pathname,
    isApp,
    isGnbOpen,
    isBoardView,
  ])
}

export default useChannelService
