import { ReactNode } from 'react'
import { URL_PATH } from '@/consts/urls'
import MaterialSymbol from '@/v1/MaterialSymbol'

type Menu = {
  text: string
  icon: ReactNode
  activeIcon: ReactNode
  path: string
  gtm: string
}

export const MENUS: Menu[] = [
  {
    text: '홈',
    icon: <MaterialSymbol name="home" />,
    activeIcon: <MaterialSymbol name="home_fill" className="fill-aqua-500" />,
    path: URL_PATH.Main,
    gtm: 'tab_main',
  },
  {
    text: '마이케어',
    icon: <MaterialSymbol name="person" />,
    activeIcon: <MaterialSymbol name="person_fill" className="fill-aqua-500" />,
    path: URL_PATH.MyCare,
    gtm: 'tab_mycare',
  },
  {
    text: '루닛케어플러스',
    icon: <MaterialSymbol name="plus_star" />,
    activeIcon: <MaterialSymbol name="plus_star" className="fill-aqua-500" />,
    path: URL_PATH.PlusMain,
    gtm: 'tab_plus',
  },
  {
    text: '메뉴',
    icon: <MaterialSymbol name="menu" />,
    activeIcon: <MaterialSymbol name="menu" className="fill-aqua-500" />,
    path: URL_PATH.AppGnb,
    gtm: 'tab_menu',
  },
]

// 탭을 미노출할 페이지
export const PATHS_WITHOUT_TAB_BAR = [
  URL_PATH.PlusAdminChat,
  URL_PATH.PlusChat,
  URL_PATH.HealthInfoAI,
  URL_PATH.Calculator,
  URL_PATH.GuidesBreast,
  URL_PATH.GuidesStomach,
  URL_PATH.GuidesThyroid,
  URL_PATH.GuidesLung,
  URL_PATH.GuidesLife,
  URL_PATH.GuidesColon,
]

// 상세 페이지 (탭 미노출)
export const DETAIL_PAGE_PATHS = [
  URL_PATH.Boards,
  URL_PATH.GuidesBreast,
  URL_PATH.GuidesStomach,
  URL_PATH.GuidesThyroid,
  URL_PATH.GuidesLung,
  URL_PATH.GuidesLife,
  URL_PATH.GuidesColon,
  URL_PATH.HospitalsMain,
  URL_PATH.Regimens,
  URL_PATH.Glossaries,
  URL_PATH.Calculator,
  URL_PATH.PlusChat,
]
