'use client'

import { useEffect } from 'react'
import useChannelService from '@/hooks/useChannelService'
import useGTMInit from '@/hooks/useGTMInit'
import useResetMainScrollPosition from '@/hooks/useResetMainScrollPosition'
import storeQueryInSession from '@/utils/storeQueryInSession'
import useAppMessage from './useAppMessage'
import useAppUnregisterToast from './useAppUnregisterToast'
import useGlobalAlert from './useGlobalAlert'
import useRouteChange from './useRouteChange'

/**
 * 앱의 클라이언트 초기화 훅을 실행하기 위한 컴포넌트
 * 앱 시작 시 실행해야 하는 훅 및 앱 전역에서 사용하는 훅을 모아둔다.
 */
const ClientInit = () => {
  // 세션에 쿼리 파라미터 저장
  useEffect(() => {
    storeQueryInSession()
  }, [])

  // GTM 초기화, 유저 아이디 전달
  useGTMInit()
  // 메인 스크롤 위치 초기화
  useResetMainScrollPosition()
  // 채널톡 서비스 초기화
  useChannelService()
  useGlobalAlert()
  useAppMessage()
  useAppUnregisterToast()
  useRouteChange()

  useEffect(() => {
    // 사파리 브라우저에서 뒤로가기 시 캐시된 페이지를 보여주는 문제 해결
    const handlePageShow = (event: PageTransitionEvent) => {
      if (event.persisted) {
        location.reload()
      }
    }

    window.addEventListener('pageshow', handlePageShow)

    return () => {
      window.removeEventListener('pageshow', handlePageShow)
    }
  }, [])

  return null
}

export default ClientInit
